// Colors
$gray-color: #f2f2f2;
$black-color: #292929;
$light-black-color: #494949;
$light-white-color: #dcdcdc;
$lighter-black-color: #767676;
$white-color: #ffffff;
$dark-gray-color: darken($gray-color, 10%);
$gray-light: #636c72;


// Pallete colors
$primary-color: #002e5b;
$danger-color: #F44336;
$success-color: #4CAF50;
$warning-color: #FF9800;
$info-color: #607D8B;


// Fonts
$st-body-font: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$st-heading-font: 'Montserrat', -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$st-heading-font-weight: 400;
$font-size-base: 0.92rem;
$line-height-base: 1.5;
